import {API_PATH, HTTP_OK} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {
  intransistChallanActions,
  fetchIntransistChallanName,
  fetchAutoSuggestion,
  fetchFilteredData,
  postOpenCloseChallanName,
  fetchIntransistChallanCreator,
  enableDisableIntransitChallansName,
  fetchVoucherNumberData,
  fetchRecieveChallans,
  intransitChallanDivisionTypeName,
  challanAuditDataTypeName,
  challanAuditReportTypeName,
  challanReceivesearchDataTypName,
  challanReceiveAutoSuggestTypeName,
} from './slice';

export function* fetchInventoryIntransistChallansWatcher() {
  yield takeLatest(fetchIntransistChallanName, fetchIntransistChallansWorker);
}
export function* fetchAutoSuggestionWatcher() {
  yield takeLatest(fetchAutoSuggestion, fetchAutoSuggestionsWorker);
}
export function* fetchInTransitFilteredDataWatcher() {
  yield takeLatest(fetchFilteredData, fetchFilteredDataWorker);
}
export function* postIntransitChallanOpenCloseWatcher() {
  yield takeLatest(
    postOpenCloseChallanName,
    postIntransitChallanOpenCloseWorker,
  );
}
export function* enableDisableIntransitChallansWatcher() {
  yield takeLatest(
    enableDisableIntransitChallansName,
    enableDisableIntransitChallansWorker,
  );
}

export function* fetchVoucherNumberDataWatcher() {
  yield takeLatest(fetchVoucherNumberData, fetchVoucherNumberDataWorker);
}

export function* fetchRecievedChallansDataWatcher() {
  yield takeLatest(fetchRecieveChallans, fetchRecievedDataWorker);
}
export function* fetchChallanAuditDataWatcher() {
  yield takeLatest(challanAuditDataTypeName, fetchChallanAuditDataWorker);
}
export function* fetchChallanAuditReportWatcher() {
  yield takeLatest(challanAuditReportTypeName, fetchChallanAuditReportWorker);
}
export function* fetchChallanReceivesearchDataWatcher() {
  yield takeLatest(
    challanReceivesearchDataTypName,
    fetchChallanReceivesearchDataWorker,
  );
}
export function* fetchChallanReceiveAutoSuggestWatcher() {
  yield takeLatest(
    challanReceiveAutoSuggestTypeName,
    filterChallanReceiveAutoSuggestWorker,
  );
}
export function* fetchIntransistChallansWorker(action: any): any {
  try {
    yield put(intransistChallanActions.setIntransistChallanInit());
    yield put(intransistChallanActions.setIntransistChallansSuccess([]));
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}
export function* fetchAutoSuggestionsWorker(action: any): any {
  try {
    if (action && action.payload) {
      let chllan = false;
      if (action.payload.isOpenChallans) {
        chllan = action.payload.isOpenChallans;
      }
      const url = `${API_PATH.inventoryModule.autoSuggestionData}/${chllan}/${action.payload.isActive}`;
      const autoSuggestionData = yield call(
        NetworkService.post,
        url,
        {...action.payload},
        null,
      );
      if (autoSuggestionData?.status === 200) {
        yield put(
          intransistChallanActions.setAutoSuggestionDataSuccess(
            autoSuggestionData?.data,
          ),
        );
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}
export function* fetchFilteredDataWorker(action: any): any {
  try {
    const {pageNumber, pageSize} = action.payload;

    if (action?.payload?.isDownload) {
      yield put(intransistChallanActions.setInTransitFilteredDownloadData());
    } else {
      yield put(intransistChallanActions.setInTransitFilteredData());
    }

    yield put(intransistChallanActions.setIntransistChallanInit());

    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.isOpenChallans !== 'undefined'
    ) {
      const url = `${API_PATH.inventoryModule.filteredData}/${action.payload.isOpenChallans}/${action.payload.isActive}?pageNumber=${pageNumber}&offsetValue=${pageSize}`;
      const filteredData = yield call(
        NetworkService.post,
        url,
        {...action.payload},
        null,
      );
      if (filteredData?.status === 200) {
        if (action?.payload?.isDownload) {
          yield put(
            intransistChallanActions.setFilteredDownloadDataSuccess(
              filteredData.data.adminIntransitChallanMasterDtoLst,
            ),
          );
        } else {
          yield put(
            intransistChallanActions.setTotalIntransitChallans(
              filteredData.data.totalRecords,
            ),
          );

          yield put(
            intransistChallanActions.setFilteredDataSuccess(
              filteredData.data.adminIntransitChallanMasterDtoLst,
            ),
          );
        }
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}
export function* postIntransitChallanOpenCloseWorker(action: any): any {
  try {
    if (action && action.payload) {
      const {closeOpen, challToBeOpenOrClose} = action.payload;
      const url = `${API_PATH.inventoryModule.postIntransitChallanOpenClose}/${closeOpen}`;
      const response = yield call(
        NetworkService.post,
        url,
        challToBeOpenOrClose,
        null,
      );
      if (response?.data) {
        yield put(fetchIntransistChallanCreator({isOpenClose: !closeOpen}));
      } else {
        yield put(intransistChallanActions.setIntransistChallanError(''));
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}
export function* enableDisableIntransitChallansWorker(action: any): any {
  try {
    yield put(intransistChallanActions.setIntransistChallanInit());
    if (action && action.payload) {
      const {isToggled, challToBeToggled, isOpenChallans} = action.payload;
      const url = `${API_PATH.inventoryModule.enableDisableIntransitChallans}/${isToggled}`;
      const response = yield call(
        NetworkService.post,
        url,
        challToBeToggled,
        null,
      );
      if (response?.data) {
        yield put(fetchIntransistChallanCreator({isOpenClose: isOpenChallans}));
      } else {
        yield put(intransistChallanActions.setIntransistChallanError(''));
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}

export function* fetchVoucherNumberDataWorker(action: any): any {
  try {
    yield put(intransistChallanActions.setIntransistChallanInit());
    if (action && action.payload) {
      const {
        payload: {voucherNumber},
      } = action;
      const url = `${API_PATH.inventoryModule.voucherNumberData}/${voucherNumber}`;
      const response = yield call(NetworkService.get, url, {}, {});
      if (response?.data) {
        yield put(
          intransistChallanActions.setVoucherNumberData({voucherNumber}),
        );
      } else {
        yield put(intransistChallanActions.setIntransistChallanError(''));
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}

export function* fetchRecievedDataWorker(action: any): any {
  try {
    if (action && action?.payload?.recievedChallans) {
      const url = `${API_PATH.inventoryModule.receivedChallans}`;
      const recievedData = yield call(
        NetworkService.post,
        url,
        action.payload.recievedChallans,
        null,
      );
      if (recievedData?.status === 200) {
        yield put(
          intransistChallanActions.setRecievedChallansData(recievedData?.data),
        );
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  } finally {
    action.payload.onComplete();
  }
}

export function* fetchIntransitChallanDivivionsWatcher() {
  yield takeLatest(
    intransitChallanDivisionTypeName,
    fetchIntransitChallanDivivionsWorker,
  );
}

export function* fetchIntransitChallanDivivionsWorker(action: any): any {
  try {
    yield put(
      intransistChallanActions.setDivisionData({
        divisionData: [],
        divisionLoading: true,
        divisionError: '',
      }),
    );
    // yield wait(3000);
    const response = yield call(
      NetworkService.get,
      API_PATH.inventoryModule.cnfDivisions,
      null,
      null,
    );
    if (response?.status === 200) {
      yield put(
        intransistChallanActions.setDivisionData({
          divisionData: response?.data,
          divisionLoading: false,
          divisionError: '',
        }),
      );
    } else {
      yield put(
        intransistChallanActions.setDivisionData({
          divisionData: [],
          divisionLoading: false,
          divisionError: 'Error while fetching divisions',
        }),
      );
    }
  } catch (e) {
    yield put(
      intransistChallanActions.setDivisionData({
        divisionData: [],
        divisionLoading: false,
        divisionError: 'Error while fetching divisions',
      }),
    );
  }
}

export function* fetchChallanAuditDataWorker(action: any): any {
  yield put(intransistChallanActions.setReportLoader(true));
  try {
    if (action && action.payload) {
      const url = `${API_PATH.inventoryModule.challanAuditData}?pageNum=${action.payload.pageNumber}`;
      const recievedData = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );
      if (recievedData?.status === 200) {
        yield put(
          intransistChallanActions.setChallanAuditData(recievedData?.data),
        );
      }
      yield put(intransistChallanActions.setReportLoader(false));
    }
  } catch (e) {
    yield put(intransistChallanActions.setReportLoader(false));
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}

export function* fetchChallanAuditReportWorker(action: any): any {
  try {
    const url = `${API_PATH.inventoryModule.challanReportData}?searchData=${action.payload.searchData}&returnInBase64=${action.payload.returnInBase64}`;

    const response = yield call(
      NetworkService.post,
      url,
      action.payload.challanPayload,
    );
    if (response.status === HTTP_OK) {
      const fileName = 'audit_trail_data';
      const url = `data:text/csv;base64,${response.data}`;
      const outputFilename = `${fileName}.csv`;

      // Create a Blob with the CSV data
      const blob = new Blob([response.data], {type: 'text/csv'});

      // Create a download link
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', outputFilename);

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    }
    // ...
  } catch (error) {}
}

export function* fetchChallanReceivesearchDataWorker(action: any): any {
  try {
    if (action && action.payload) {
      const url = `${API_PATH.inventoryModule.challanReceiveSearchData}/${action.payload?.searchData}?pageNum=${action.payload.pageNumber}`;
      const recievedData = yield call(
        NetworkService.post,
        url,
        action.payload?.challanPayload,
        null,
      );
      if (recievedData.status === HTTP_OK) {
        yield put(
          intransistChallanActions.setChallanReceivesearchData(
            recievedData?.data,
          ),
        );
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}

export function* filterChallanReceiveAutoSuggestWorker(action: any): any {
  try {
    yield put(intransistChallanActions.setLoading(true));
    if (action.payload) {
      const url = `${API_PATH.inventoryModule.challanAutoSuggest}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );

      if (response?.status === 200) {
        yield put(
          intransistChallanActions.setChallanReceiveAutoSuggest(response?.data),
        );
        yield put(intransistChallanActions.setLoading(false));
      } else {
        yield put(intransistChallanActions.setLoading(false));
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setLoading(false));
  }
}
